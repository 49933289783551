/* Home page CMS styles */
.ulHistRecs > li > a { font-weight:bold; }
.ulHistRecs ~ ul { margin-top:12px; }
.ulHistRecs > li:not(:first-child) { margin-top:20px; }
.allCardCat { margin-top:20px; text-align:center; }
#communityForm { margin-top: 12px; }
#userName {margin-right:4px;}

.leftColls ul, .rightColls ul { margin-left:16px; }
.bottomColls { clear:both; text-align:center; }
.colls { margin-bottom: 16px; }
html:not(.bonsai) .bottomColls a, html:not(.bonsai) .leftColls a, html:not(.bonsai) .rightColls a, html:not(.bonsai) .colls a {
	color: var(--ui-text-color);
	text-decoration: underline;
}
@media only screen and (min-width:768px) {
	.leftColls, .rightColls { float:left; width:50%; }
	.rightColls { padding-left:40px; }
	.colls > ul > li { display:list-item; }
}

.featText { margin-bottom:16px; min-height:60px; padding:4px 0 0 75px; position:relative; }
.featImg { height:65px; left:0; position:absolute; top:5px; width:65px; }

ul.recentActivityList { margin-top:12px; }
ul.recentActivityList li { display:table-row; }
ul.recentActivityList li > div { display:table-cell; padding-bottom:8px; }
ul.recentActivityList li > div.icon { width:40px; color:#868686; text-align:center; }
.recentActivityContent .category { color:#868686; font-size:14px; padding:2px 0; }

.searchHome .recentSearchesWidget, .searchHome .recentSearchesWidgetApp .alert { margin-top: 20px; }

.recentSearchesWidget { padding-bottom: 10px; }
.recentSearchesWidget .recentSearchBody { margin-left:-12px; }
.recentSearchesWidget li { margin-bottom: 10px; }

.recentSearchBody.recentSearchBodyMobile { display: grid; grid-template-columns: 1fr 1fr; text-overflow: clip; gap: 12px 20px; }
.recentSearchBody.recentSearchBodyMobile .recent-search-item { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
a.recent-search-link { padding-right: 2px;}
a.recent-search-link .icon.iconMore { margin-left: 5px; }
.recentSearchesWidget .conHeader .icon.iconHelp { margin-left: 5px; margin-right: 10px; font-size: 16px; }
a.seeMoreLink { float: right;  padding-right: 16px; font-weight: 600; font-size: 13px; line-height: 16px; text-wrap: nowrap; }

@media only screen and (max-width:768px) {
	.searchHome .recentSearchesWidget { margin-top: 20px; }
	#browseApp { margin-top: 20px; }
	#recentViewedCollections { margin-top: 20px; }
	.searchHome .cmsBottomContent { margin-top: 20px; }
	#whatsNew { margin-top: 20px; }
	.searchHome .cmsRightContent { margin-top: 20px; }
	.searchHome .communitySearch { margin-top: 20px; }
}

div.partnerAttributionLogo { float:left; padding-right:10px; padding-top:5px; vertical-align:top; }
div.partnerAttributionDesc.wrap { overflow:hidden; vertical-align:top; }

#longDescription li { line-height:1.25em; list-style-type:disc; margin-left:24px; }

/* Legacy Map */
.innerMapContainer { padding:8px; border:1px solid #d0c4a9; border-radius:4px; float:left; margin-right:8px; }
.innerMapContainer li { list-style-type:disc; margin-left:20px; }
.innerMapContainer.us { width:144px; }
.innerMapContainer.uk { width:300px; }
.innerMapContainer.eu { width:215px; }
.innerMapContainer.ca { width:180px; }
.innerMapContainer.au { width:265px; }
.mapGraphic { overflow:hidden; }
.mapLinks { clear:both; padding-top:8px; }
.mapLinks .c_list a { display:block; }
.mapLinks td { padding:0 10px; }
.mapLinks td a { display:block; margin-top:4px; }

@media only screen and (max-width:1005px) {
	.innerMapContainer { margin-bottom:16px; width:95%!important; }
	.mapLinks td { border-bottom:1px solid #ccc; display:block; float:left; margin-bottom:12px; padding-bottom:12px }
	.mapGraphic { float:left; }
}
@media only screen and (max-width:767px) {
	.mapGraphic { display:none; }
	#communitySearchButton { margin-top:0px; }
}

.featColl ul li { list-style:disc; margin: 0 0 8px 16px; }

/* Group: About this collection */
.divSample { cursor:pointer; margin-top:8px; }
.divSample + .conTitle { margin-top:12px; }
.divSample img { max-height:90px; }
.divSample img + span { display:inline-block; }
.conTitleLeft {float:left; }
#whatsNew .orange {color: #E3720f;}
#whatsNew .listView.conTitle {padding-bottom: 0px;}
#whatsNew.listView li {margin-top: 12px;}
#whatsNew .white {color: #ffffff;}
#whatsNew.promoView.conSecond {display: flex; flex-direction: column; padding: 0px; border: 0px;}
#whatsNew.teamPhoto { background: no-repeat; background-size: 100%; height: 0; position: relative;}
#whatsNew.promoView .gradientLayer .promoDescription {position: absolute; top: 0; bottom: 0; left: 0; right: 0; margin: 20px 20px 12px 20px; display: flex; flex-direction: column; }
#whatsNew.promoView .gradientLayer .promoDescription p {overflow: hidden;}
#whatsNew.promoView .conBody {margin-top: auto;}
#whatsNew.promoView .promoSubTitle {font-size: 16px; color: #ffb800;}
#whatsNew.promoView .promoTitle {font-size: 20px; font-weight: bold; margin-top: 0px; overflow: hidden;}
#whatsNew.promoView .gradientLayer { background: linear-gradient(to bottom, rgba(0,0,0,0) 37%, rgba(0,0,0,1)); border-radius:6px; height: 100%; left: 0; position: absolute; top: 0;  width: 100%; }

@media only screen and (max-width:1080px) {
	#whatsNew.promoView .promoDescription p {text-overflow: ellipsis; white-space: nowrap;}
}

.newsGrid {margin-top: 10px; margin-bottom: 10px; margin-right: 10px;}
.newsDate {text-align: right;}
.textCenter {text-align: center;}

@media only screen and (max-width:767px) {
	.newsDate {text-align: left; }
	.newsGrid .ancColRow {padding-top: 13px;}
}

.recent-search-callout { display: grid; grid-template-columns: auto auto; column-gap: 7px; };
.calloutContent .recent-search-callout span.title { justify-self: end; font-weight: bold !important; }
.recent-search-item-callout .title { justify-self: end; font-weight: bold; }
.recent-search-item-callout .value { text-transform: capitalize; }

.searchModeTabs {
	background-color: #fff;
	padding-top: 10px;
	padding-left: 20px;
}

.searchModeTabs .tabs {
	text-align: left;
}

.toggleToSmartBtn {
	display: flex;
	justify-content: flex-end;
}
@media (min-width: 767px) {
	.toggleToSmartBtnGuidance {
		transform: translate(-35%, 0px);
	}
}

.toggleToSmartBtnGuidance .guidanceControlPrevious {
	display: none;
}
.simpleSearchForm {
    width: 748px;
}
